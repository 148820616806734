import React, { useState } from "react";
import {
  View,
  Image,
  TextInput,
  TouchableOpacity,
  Text,
  Linking,
  StyleSheet,
} from "react-native";

import { loginEmailPassword } from "../../App";
import { useAppContext } from "../components/AppContext";
import { useNavigation } from "@react-navigation/native";

const LoginScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { setUser } = useAppContext();
  const navigation = useNavigation();

  const handleLogin = async () => {
    try {
      u = await loginEmailPassword(email, password);
      setUser(u);
      console.log(u);
      // User successfully logged in, navigate to MainScreen
      //navigation.replace('MainScreen');
    } catch (error) {
      // Handle login failure
      setErrorMessage("Incorrect email or password");
    }
  };

  const handleSignUp = () => {
    // Open www.topwanders.com in the default browser
    navigation.navigate("NewAccount");
  };

  const handleForgotPassword = () => {
    // Open www.topwanders.com in the default browser
    navigation.navigate("Forgor");
  };

  return (
    <View style={styles.container}>
      <Image
        source={require("../assets/logonaszybkobiale.png")}
        style={styles.logo}
        resizeMode="contain"
      />
      <TextInput
        placeholder="Email"
        value={email}
        onChangeText={setEmail}
        style={styles.input}
      />
      <TextInput
        placeholder="Password"
        value={password}
        onChangeText={setPassword}
        secureTextEntry
        style={styles.input}
      />
      {errorMessage ? (
        <Text style={styles.errorMessage}>{errorMessage}</Text>
      ) : null}
      <TouchableOpacity onPress={handleForgotPassword}>
        <Text style={styles.signUpLink}>Forgot password?</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={handleLogin} style={styles.loginButton}>
        <Text>Log In</Text>
      </TouchableOpacity>

      <Text style={styles.orText}>-------- OR --------</Text>

      <TouchableOpacity onPress={handleSignUp} style={styles.loginButton}>
        <Text>Make a free account</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "red",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    width: 350,
    height: 100,
  },
  input: {
    backgroundColor: "white",
    width: 200,
    padding: 10,
    marginVertical: 10,
    borderRadius: 5,
  },
  loginButton: {
    backgroundColor: "white",
    padding: 10,
    marginVertical: 10,
    borderRadius: 5,
  },
  signUpLink: {
    color: "white",
    textDecorationLine: "underline",
  },
  errorMessage: {
    color: "white",
    marginTop: 10,
  },
  orText: {
    color: "white",
    fontSize: 14, // Adjust font size as per your design
    textAlign: "center", // Center align the text
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 20,
    marginTop: 20,
  },
});

export default LoginScreen;

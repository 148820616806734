import Slider from "@react-native-community/slider";
import {
  Text,
  View,
  TouchableOpacity,
  ActivityIndicator,
  LayoutAnimation,
  UIManager,
  Image,
  StyleSheet,
} from "react-native";
import { useAppContext } from "./AppContext";
import { useState } from "react";
import Icon from "react-native-vector-icons/FontAwesome";
import { useEffect } from "react";

const AudioControlSlider = (location) => {
  const [loading, setLoading] = useState(false);
  const totalLength = location.location.audioLength;
  const [currentPosition, setCurrentPosition] = useState(0);

  const [isThisLocationPlaying, setIsThisLocationPlaying] = useState(false);

  const {
    userLocation,
    setUserLocation,
    locations,
    fetchNearbyLocations,
    fetchLocationById,
    audioguide,
    setAudioSpeed,
    startNewAudio,
    playAudio,
    pauseAudio,
    isAudioPlaying,
    audioLocation,
    currentAudioPosition,
    getTotalAudioLength,
    setCurrentAudioPositionSlider,
    isAudioFinished,
  } = useAppContext();

  function toHHMMSS(secs) {
    const sec_num = parseInt(secs, 10);
    const hours = Math.floor(sec_num / 3600);
    const minutes = Math.floor(sec_num / 60) % 60;
    const seconds = sec_num % 60;

    return [hours, minutes, seconds]
      .map((v) => (v < 10 ? "0" + v : v))
      .filter((v, i) => v !== "00" || i > 0)
      .join(":");
  }
  function toSeconds(time) {
    const [minutes, seconds] = time.split(":").map(Number);
    return minutes * 60 + seconds;
  }

  const togglePlay = (location) => {
    if (isAudioPlaying && audioLocation.title == location.title) {
      pauseAudio();
    } else {
      if (!isAudioFinished && audioLocation.title == location.title) {
        playAudio();
      } else {
        console.log(location);
        startNewAudio(location);
      }
    }
  };

  const onSeek = (time) => {
    setCurrentAudioPositionSlider(time);
  };

  return location.location.audioLength ? (
    <View>
      <View>
        <View style={styles.rowContainer}>
          {(loading && (
            <View style={{ margin: 18 }}>
              <ActivityIndicator size="large" color="#FFF" />
            </View>
          )) || (
            <View style={styles.actionsContainer}>
              <TouchableOpacity
                style={[styles.iconContainer, styles.playBtn]}
                onPress={() => togglePlay(location.location)}
              >
                <Icon
                  name={
                    isAudioPlaying &&
                    audioLocation.title == location.location.title
                      ? "pause"
                      : "play"
                  }
                  size={24}
                  style={styles.playIcon}
                />
              </TouchableOpacity>
            </View>
          )}

          <View style={styles.sliderContainer}>
            <Slider
              style={styles.slider}
              minimumValue={0}
              maximumValue={Math.max(
                toSeconds(totalLength),
                1,
                currentAudioPosition + 1
              )}
              minimumTrackTintColor={"grey"}
              maximumTrackTintColor={"grey"}
              onSlidingComplete={onSeek}
              thumbTintColor="red"
              value={
                audioLocation.title == location.location.title
                  ? currentAudioPosition
                  : 0
              }
            />
            <View style={styles.durationContainer}>
              <Text style={styles.timeText}>
                {audioLocation.title == location.location.title
                  ? toHHMMSS(currentAudioPosition)
                  : toHHMMSS(0)}
              </Text>
              <Text style={styles.timeText}>{totalLength}</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  ) : (
    <View>
      <View>
        <View style={styles.rowContainerJustPlay}>
          {(loading && (
            <View style={{ margin: 18 }}>
              <ActivityIndicator size="large" color="#FFF" />
            </View>
          )) || (
            <View style={styles.actionsContainer}>
              <TouchableOpacity
                style={[styles.iconContainer, styles.playBtn]}
                onPress={() => togglePlay(location.location)}
              >
                <Icon
                  name={
                    isAudioPlaying &&
                    audioLocation.title == location.location.title
                      ? "pause"
                      : "play"
                  }
                  size={24}
                  style={styles.playIcon}
                />
              </TouchableOpacity>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  rowContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",

    marginRight: 20,
  },
  rowContainerJustPlay: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",

    marginRight: 20,
  },
  iconContainer: {
    alignSelf: "center",
    position: "relative",
  },
  playBtn: {
    justifyContent: "center",
    alignItems: "center",
  },
  sliderContainer: {
    paddingHorizontal: 16,
    paddingBottom: 12,
    width: "100%",
  },
  slider: {
    height: 30,
    width: "100%",
    marginTop: 24,
  },
  durationContainer: { flexDirection: "row", justifyContent: "space-between" },
  actionsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",

    marginBottom: 10,
  },
  crossLine: {
    position: "absolute",
    transform: [{ rotate: "-60deg" }],
    top: 15,
    left: -1,
    width: 30,
    height: 1,
    borderBottomColor: "#fff",
    borderBottomWidth: 2,
  },

  timeText: {
    color: "#000",
    fontSize: 18,
  },
  playIcon: {
    marginLeft: 10,
    color: "gray",
    size: 24,
  },
});

export default AudioControlSlider;

import React, { useState, useRef, forwardRef } from "react";
import {
  View,
  Image,
  TextInput,
  TouchableOpacity,
  Text,
  Linking,
  StyleSheet,
} from "react-native";

import { useNavigation } from "@react-navigation/native";
import ReCaptcha from "../components/ReCaptcha.web";
import { useAppContext } from "../components/AppContext";
import { firestore, storage, auth } from "../../App";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";
import { doc, serverTimestamp, setDoc } from "firebase/firestore/lite";

const MakeAccountScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [name, setName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [receiveUpdates, setReceiveUpdates] = useState(false);
  const navigation = useNavigation();
  const { reCaptchaVerified, setreCaptchaVerified, setNewUser, setNewGoogle } =
    useAppContext();

  const handleSignUp = () => {
    //All the conditions on making an account
    if (!name) {
      setErrorMessage("Please enter a name.");
      return;
    }

    if (!emailIsValid(email)) {
      setErrorMessage("Please enter a valid email.");
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    if (!passwordMeetsCriteria(password)) {
      setErrorMessage("Password must be at least 6 characters long.");
      return;
    }

    if (!reCaptchaVerified) {
      setErrorMessage("Verify the reCaptcha.");
      return;
    }

    if (!termsAccepted) {
      setErrorMessage("Please accept the Terms and Conditions.");
      return;
    }

    //Making the account
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // User creation successful
        updateProfile(auth.currentUser, { displayName: name }).then(() => {
          console.log("Trying to send a verification email.");
          sendEmailVerification(auth.currentUser)
            .then(() => {
              console.log("Email verification email sent successfully.");
            })
            .catch((error) => {
              console.error("Error sending email verification:", error);
            });
          // Saving data to firestore
          const userInfoDoc = doc(firestore, "UserInfo", email);
          setDoc(userInfoDoc, {
            email: email,
            name: name,
            newsletter: receiveUpdates,
            date: serverTimestamp(),
          });
          setNewUser(true);
          setNewGoogle(false);
        });
      })
      .catch((error) => {
        if (error.code === "auth/email-already-in-use") {
          setErrorMessage("This email is already in use with an account.");
          return;
        } else {
          setErrorMessage("Unexpected error occurred");
          return;
        }
      });

    // Clearing error message
    setErrorMessage("");
  };

  const handleTermsPress = () => {
    Linking.openURL("https://kuriocity.com/privacy-policy/");
  };

  const emailIsValid = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const passwordMeetsCriteria = (password) => {
    return password.length >= 6;
  };

  const handleBack = () => {
    navigation.goBack();
  };

  const handleVerify = () => {
    console.log("Verified");
  };

  return (
    <View style={styles.container}>
      <Image
        source={require("../assets/logonaszybkobiale.png")}
        style={styles.logo}
        resizeMode="contain"
      />
      <Text style={styles.instructionsText}>
        Please fill in your details to make an account.
      </Text>
      <TextInput
        placeholder="Name"
        value={name}
        onChangeText={setName}
        style={styles.input}
      />
      <TextInput
        placeholder="Email"
        value={email}
        onChangeText={setEmail}
        style={styles.input}
      />
      <TextInput
        placeholder="Password"
        value={password}
        onChangeText={setPassword}
        secureTextEntry
        style={styles.input}
      />
      <TextInput
        placeholder="Confirm Password"
        value={confirmPassword}
        onChangeText={setConfirmPassword}
        secureTextEntry
        style={styles.input}
      />
      <View style={{ marginTop: 10 }}>
        <ReCaptcha onVerify={handleVerify}></ReCaptcha>
      </View>
      <View style={styles.checkboxContainer}>
        <TouchableOpacity
          onPress={() => setTermsAccepted(!termsAccepted)}
          style={styles.checkbox}
        >
          {termsAccepted ? (
            <Text style={styles.checkboxText}>☑️</Text>
          ) : (
            <Text style={styles.checkboxText}>▢</Text>
          )}
          <Text style={styles.checkboxText}>
            Accept{" "}
            <TouchableOpacity onPress={handleTermsPress}>
              <Text style={styles.signUpLink}>Terms and Conditions</Text>
            </TouchableOpacity>
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => setReceiveUpdates(!receiveUpdates)}
          style={styles.checkbox}
        >
          {receiveUpdates ? (
            <Text style={styles.checkboxText}>☑️</Text>
          ) : (
            <Text style={styles.checkboxText}>▢</Text>
          )}
          <Text style={styles.checkboxText}>
            Receive updates about KurioCity and special offers through email
          </Text>
        </TouchableOpacity>
      </View>
      {errorMessage ? (
        <Text style={styles.errorMessage}>{errorMessage}</Text>
      ) : null}

      <TouchableOpacity onPress={handleSignUp} style={styles.loginButton}>
        <Text>Make an account</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={handleBack}>
        <Text style={styles.backButton}>Back</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "red",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    width: 350,
    height: 100,
  },
  backButton: {
    color: "white",
    textDecorationLine: "underline",
  },
  input: {
    backgroundColor: "white",
    width: 200,
    padding: 10,
    marginVertical: 10,
    borderRadius: 5,
  },
  loginButton: {
    backgroundColor: "white",
    padding: 10,
    marginVertical: 10,
    borderRadius: 5,
  },
  signUpLink: {
    color: "white",
    textDecorationLine: "underline",
  },
  errorMessage: {
    color: "white",
    marginTop: 10,
  },
  orText: {
    color: "white",
    fontSize: 14, // Adjust font size as per your design
    textAlign: "center", // Center align the text
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 20,
    marginTop: 20,
  },
  instructionsText: {
    color: "white",
    fontSize: 14, // Adjust font size as per your design
    textAlign: "center", // Center align the text
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 15,
  },
  checkboxContainer: {
    marginTop: 10,
    marginBottom: 10,
    alignItems: "center",
  },
  checkbox: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 5,
  },
  checkboxText: {
    color: "white",
    marginLeft: 5,
  },
});

export default MakeAccountScreen;

import React, { useEffect, useState } from "react";
import { View, Text, Image, Pressable, StyleSheet } from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import { useAppContext } from "../components/AppContext";
//TEMP FOR LEARNING HOW TO PLAY SOUNDS
import Sound from "react-native-web-sound";
import { useNavigation } from "@react-navigation/native";

const AudioControlsBar = () => {
  const [speed, setSpeed] = useState("x1.0");
  const navigation = useNavigation();

  const {
    userLocation,
    setUserLocation,
    locations,
    fetchNearbyLocations,
    fetchLocationById,
    audioguide,
    setAudioSpeed,
    startNewAudio,
    playAudio,
    pauseAudio,
    isAudioPlaying,
    audioLocation,
    getCurrentAudioPosition,
    getTotalAudioLength,
    isAudioFinished,
  } = useAppContext();

  // const togglePlay = () => {
  //   if (isAudioPlaying) {
  //     pauseAudio();
  //     //audioguide.pause();
  //   } else {
  //     playAudio();
  //   }
  // };
  const togglePlay = (location) => {
    if (isAudioPlaying) {
      pauseAudio();
    } else {
      if (!isAudioFinished) {
        playAudio();
      } else {
        console.log(audioLocation);
        if ((audioLocation.title = "Welcome to KurioCity")) {
          playAudio();
        } else {
          //startNewAudio(audioLocation);   //This is annoying to deal with - fix later
        }
      }
    }
  };

  const handleLocationPress = (location) => {
    // Handle the press on a location item

    console.log(`Pressed on ${location.title}`);
    navigation.navigate("Location", { location });
  };

  return (
    <Pressable
      onPress={() => {
        handleLocationPress(audioLocation);
      }}
    >
      <View style={styles.audioBar}>
        {/* Left side - Cover image and title */}
        <View style={styles.viewContainer}>
          <Image
            source={
              audioLocation.thumbnail
                ? { uri: audioLocation.thumbnail }
                : require("../assets/noPicture.png")
            }
            style={styles.coverImage}
          />
          <Text
            style={styles.audioTitle}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {audioLocation.title}
          </Text>
        </View>

        {/* Right side - Playback Speed and Play icon */}
        <View style={styles.viewContainer2}>
          <Pressable
            style={styles.speedControl}
            onPress={() => {
              // Change speed options accordingly
              setSpeed((prevSpeed) => {
                switch (prevSpeed) {
                  case "x1.0":
                    setAudioSpeed(1.2);
                    return "x1.2";
                  case "x1.2":
                    setAudioSpeed(1.5);
                    return "x1.5";
                  case "x1.5":
                    setAudioSpeed(2);
                    return "x2.0";
                  case "x2.0":
                    setAudioSpeed(1);
                    return "x1.0";
                  default:
                    return "x1.0";
                }
              });
            }}
          >
            <Text style={styles.speedText}>{speed}</Text>
          </Pressable>

          <Pressable style={styles.playButton} onPress={togglePlay}>
            <Icon
              name={isAudioPlaying ? "pause" : "play"}
              size={24}
              style={styles.playIcon}
            />
          </Pressable>
        </View>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  audioBar: {
    flexDirection: "row",
    backgroundColor: "red",
    height: 50,
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 10,
  },
  viewContainer: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1, // This ensures the title takes available space
  },
  viewContainer2: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end", // This ensures elements are aligned to the right
  },
  coverImage: {
    width: 40, // Set your preferred width
    height: 40, // Set your preferred height
    marginRight: 10,
  },
  audioTitle: {
    color: "white",
    fontSize: 20,
    flexShrink: 1, // Allows the title to shrink if needed
  },
  playButton: {
    alignSelf: "center",
  },
  speedControl: {
    marginHorizontal: 20,
    paddingVertical: 5,
  },
  speedText: {
    color: "white",
    fontSize: 25,
  },
  playIcon: {
    color: "white",
  },
});

export default AudioControlsBar;

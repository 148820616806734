import { useIsFocused, useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Button,
  Image,
  ScrollView,
  TouchableOpacity,
  StyleSheet,
  Platform,
} from "react-native";
import { useAppContext } from "./AppContext";
import Icon from "react-native-vector-icons/FontAwesome";

const NavigationBar = () => {
  const navigation = useNavigation();

  const [currentRouteName, setCurrentRouteName] = useState("Home");

  const {
    userLocation,
    setUserLocation,
    locations,
    fetchNearbyLocations,
    fetchLocationById,
    audioguide,
    setAudioSpeed,
    startNewAudio,
    playAudio,
    pauseAudio,
    isAudioPlaying,
  } = useAppContext();

  return (
    <View style={styles.bottomBar}>
      {/* Button Map - Not Selected */}
      <TouchableOpacity
        style={styles.button}
        onPress={() => {
          setCurrentRouteName("Map");
          navigation.navigate("Map");
          //getCoordinates();
        }}
      >
        <Icon
          name="map"
          size={25}
          style={
            currentRouteName === "Map"
              ? styles.selectedIcon
              : styles.unselectedIcon
          }
        />
      </TouchableOpacity>

      {/* Button 1 - Selected */}
      <TouchableOpacity
        style={styles.button}
        onPress={() => {
          setCurrentRouteName("Home");
          navigation.navigate("Home");
          //getCoordinates();
        }}
      >
        <Icon
          name="compass"
          size={30}
          style={
            currentRouteName === "Home"
              ? styles.selectedIcon
              : styles.unselectedIcon
          }
        />
      </TouchableOpacity>

      {/* Button 2 - Not Selected (Navigates to Profile Screen) */}
      <TouchableOpacity
        style={styles.button}
        onPress={() => {
          setCurrentRouteName("Profile");
          navigation.navigate("Profile");
          //getCoordinates();
        }}
      >
        <Icon
          name="user"
          size={30}
          style={
            currentRouteName === "Profile"
              ? styles.selectedIcon
              : styles.unselectedIcon
          }
        />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    color: "white",
    justifyContent: "center",
  },
  topBar: {
    backgroundColor: "red",
    paddingTop: 5,
    paddingBottom: 5,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  logo: {
    width: 150,
    height: 40,
    alignSelf: "flex-start",
  },
  scrollView: {
    flex: 1,
    backgroundColor: "white", //"#F0F0F0",
  },
  coordinatesText: {
    alignSelf: "center",
  },
  audioBar: {
    flexDirection: "row",
    backgroundColor: "red",
    height: 50,
    alignItems: "center",
  },
  playIcon: {
    color: "white",
    marginRight: 5,
    alignSelf: "flex-end",
  },
  bottomBar: {
    flexDirection: "row",
    backgroundColor: "#f2f6fc",
    height: 50,
  },

  button: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  selectedIcon: {
    color: "red",
  },
  unselectedIcon: {
    color: "black",
  },
  icon: {
    marginRight: 10,
  },
});

export default NavigationBar;

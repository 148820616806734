import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Button,
  Image,
  ScrollView,
  TouchableOpacity,
  StyleSheet,
  Platform,
} from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import Geolocation from "react-native-geolocation-service";
import { useNavigation } from "@react-navigation/native";

import { useAppContext } from "../components/AppContext";
import MapView, { Marker, Callout } from "../components/MyMap";
import LMap from "../components/LMap.web";

//const firestore = getFirestore();

//console.log("Hello Firestore");

function MapScreen({ route, navigation }) {
  const {
    userLocation,
    setUserLocation,
    locations,
    fetchNearbyLocations,
    fetchLocationById,
    logActivity,
  } = useAppContext();

  useEffect(() => {
    //console.log("User Location:", userLocation);
  }, [userLocation]);

  const getCoordinates = () => {
    // Assuming getCoordinates returns a Promise
    return new Promise((resolve, reject) => {
      const options = {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 1000,
      };

      if (Platform.OS === "android") {
        console.log("ANDROID NOT HANDLED YET");
      } else {
        // For iOS and other platforms
        Geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setUserLocation({ latitude, longitude });
          },
          (error) => {
            console.log("Error getting location: ", error);
          },
          options
        );
      }

      // Resolve the promise with the updated user location
      resolve("Done");
    });
  };
  if (!userLocation) {
    getCoordinates();
  }

  //Get location every 5 seconds
  useEffect(() => {
    // Run getCoordinates() automatically every 5 seconds
    const intervalId = setInterval(getCoordinates, 5000);
    console.log("Test");
    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures this effect runs only once, similar to componentDidMount

  const handleShowLocationButtonPress = async (markerLocation) => {
    // Handle button press as needed

    logActivity(markerLocation, "Map");

    const foundLocation = locations.find(
      (location) => location.id === markerLocation.id
    );

    if (foundLocation) {
      console.log(foundLocation);
      navigation.navigate("Location", { location: foundLocation });
    } else {
      console.log("The location is not loaded. Loading...");
      const foundLocation = await fetchLocationById(markerLocation.id);
      navigation.navigate("Location", { location: foundLocation });
    }
  };

  return (
    <View style={styles.container}>
      {/* Top Red Bar with Logo */}
      <View style={styles.topBar}>
        <Image
          source={require("../assets/logonaszybkobiale.png")}
          style={styles.logo}
          resizeMode="contain"
        />
      </View>
      <LMap></LMap>
      {/* <MapView
        provider="google"
        googleMapsApiKey="AIzaSyCNS5VUIUeYCkHJyOi9vZDRCgTUOBBe-9Q"
        showsUserLocation={true}
        initialCamera={{
          center: {
            latitude: userLocation.latitude,
            longitude: userLocation.longitude,
          },
          zoom: 17, // adjust this value for the desired zoom level
        }}
        loadingFallback={
          <View
            style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
          >
            <Text>Loading...</Text>
          </View>
        }
      >
        {markerLocations.map((markerLocation) => (
          <Marker
            key={markerLocation.id}
            coordinate={{
              latitude: markerLocation.latitude,
              longitude: markerLocation.longitude,
            }}
            title={markerLocation.title}
          >
            <Callout>
              
              <View>
                <Text>{markerLocation.title}</Text>
                
                <TouchableOpacity
                  style={styles.showLocationButton}
                  onPress={() => handleShowLocationButtonPress(markerLocation)}
                >
                  <Text style={{ color: "white", alignSelf: "center" }}>
                    Show details
                  </Text>
                </TouchableOpacity>
              </View>
            </Callout>
          </Marker>
        ))}
        
        <Marker
          coordinate={{
            latitude: userLocation.latitude,
            longitude: userLocation.longitude,
          }}
          title="User's Location"
          tappable={true}
          key={"userLocation"}
        >
          <View
            style={{
              paddingHorizontal: 10,
              paddingVertical: 5,
              borderRadius: 999,
              backgroundColor: "teal",
              position: "relative",
              zIndex: 19,
            }}
          >
            <View
              style={{
                position: "absolute",
                width: 10,
                height: 10,
                backgroundColor: "teal",
                zIndex: 20,
                bottom: -5,
                alignSelf: "center",
                transform: [{ rotate: "45deg" }],
              }}
            ></View>
            <Text>🧍</Text>
          </View>
        </Marker>
      </MapView> */}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,

    justifyContent: "center",
  },
  topBar: {
    backgroundColor: "red",
    paddingTop: 5,
    paddingBottom: 5,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  logo: {
    width: 150,
    height: 40,
    alignSelf: "flex-start",
  },
  scrollView: {
    flex: 1,
    backgroundColor: "#F0F0F0",
  },
  coordinatesText: {
    alignSelf: "center",
  },
  showLocationButton: {
    alignSelf: "center",
    backgroundColor: "red",
    padding: 10,
    borderRadius: 8,
    marginTop: 10,
  },
  bottomBar: {
    flexDirection: "row",
    backgroundColor: "#f2f6fc",
    height: 50,
  },
  button: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  selectedIcon: {
    color: "red",
  },
  unselectedIcon: {
    color: "black",
  },
  icon: {
    marginRight: 10,
  },
});
export default MapScreen;

import React, { useEffect, useRef, useState } from "react";
import { View, Text, StyleSheet } from "react-native";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Icon from "react-native-vector-icons/FontAwesome";
import { Icon as LIcon } from "leaflet";
import { TouchableOpacity } from "react-native-web";
import MarkerClusterGroup from "react-leaflet-cluster";
import { useAppContext } from "./AppContext";
import { useNavigation } from "@react-navigation/native";

const LMap = ({}) => {
  //In the future consider a loading mechanism for markers
  const markerLocations = require("../assets/locsForMap20240424.json");
  const mapRef = useRef(null);

  const navigation = useNavigation();

  const { userLocation, locations, fetchLocationById, logActivity } =
    useAppContext();

  const centerMapOnUserLocation = () => {
    console.log(mapRef);
    if (mapRef.current && userLocation) {
      mapRef.current.flyTo([userLocation.latitude, userLocation.longitude], 18);
    }
  };
  const customIcon = new LIcon({
    iconUrl: require("../assets/locicon.png"),
    iconSize: [25, 25],
  });
  const customIconSound = new LIcon({
    iconUrl: require("../assets/lociconSound.png"),
    iconSize: [35, 35],
  });
  const customUserIcon = new LIcon({
    iconUrl: require("../assets/lociconuser.png"),
    iconSize: [30, 30],
  });

  const handleShowLocationButtonPress = async (marker) => {
    // Handle button press as needed

    logActivity(marker, "Map");

    const foundLocation = locations.find(
      (location) => location.id === marker.id
    );

    if (foundLocation) {
      console.log(foundLocation);
      navigation.navigate("Location", { location: foundLocation });
    } else {
      console.log("The location is not loaded. Loading...");
      const foundLocation = await fetchLocationById(marker.id);
      navigation.navigate("Location", { location: foundLocation });
    }
  };

  return (
    <View style={styles.mapContainer}>
      <MapContainer
        center={
          userLocation
            ? [userLocation.latitude, userLocation.longitude]
            : [52.374004, 4.898113]
        }
        zoom={userLocation ? 17 : 15}
        style={{ width: "100%", height: "100%" }}
        ref={mapRef}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {userLocation && (
          <Marker
            position={[userLocation.latitude, userLocation.longitude]}
            key="userLocation"
            icon={customUserIcon}
          ></Marker>
        )}
        <MarkerClusterGroup
          chunkedLoading
          maxClusterRadius={48}
          disableClusteringAtZoom={17}
        >
          {markerLocations.map((marker) => (
            <Marker
              position={[marker.latitude, marker.longitude]}
              key={marker.id}
              icon={marker.audioLength ? customIconSound : customIcon}
            >
              <Popup style={styles.popup}>
                <View style={styles.popupContent}>
                  <Text>{marker.title}</Text>

                  <TouchableOpacity
                    style={styles.showLocationButton}
                    onPress={() => handleShowLocationButtonPress(marker)}
                  >
                    <Text style={{ color: "white", alignSelf: "center" }}>
                      Show details
                    </Text>
                  </TouchableOpacity>
                </View>
              </Popup>
            </Marker>
          ))}
        </MarkerClusterGroup>
      </MapContainer>
      {/* Button to center map on user location */}
      <TouchableOpacity
        style={styles.centerButton}
        onPress={centerMapOnUserLocation}
      >
        <Icon name={"location-arrow"} size={25} color={"red"} />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  popupContent: {
    flexDirection: "column",
    alignItems: "center",
    minWidth: 100,
  },
  mapContainer: {
    flex: 1,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
  },
  showLocationButton: {
    alignSelf: "center",
    backgroundColor: "red",
    padding: 10,
    borderRadius: 8,
    marginTop: 10,
  },
  centerButton: {
    position: "absolute",
    bottom: 20,
    right: 10,
    backgroundColor: "white",
    padding: 10,
    borderRadius: 8,
    zIndex: 1000, // Ensure button is rendered above map
  },
});

export default LMap;

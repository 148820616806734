import React, { useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Image,
  Linking,
  Pressable,
} from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";

import { useAppContext } from "../components/AppContext";
import { auth } from "../../App";
import { sendPasswordResetEmail, signOut } from "firebase/auth";
import FeedbackModal from "../components/FeedbackModal";
import { Modal, ScrollView } from "react-native-web";

const ProfileScreen = ({ navigation }) => {
  const { user, userLocation } = useAppContext();
  const [feedbackModalVisible, setFeedbackModalVisible] = useState(false);
  const [passwordModalVisible, setpasswordModalVisible] = useState(false);

  const handleSignOut = async () => {
    await signOut(auth);
  };

  const handleWebsite = async () => {
    Linking.openURL("https://kuriocity.com/");
  };
  const handleInstagram = async () => {
    Linking.openURL("https://www.instagram.com/kuriocity_guides/");
  };
  const handleTiktok = async () => {
    Linking.openURL("https://www.tiktok.com/@kuriocity_guide");
  };
  const handleYoutube = async () => {
    Linking.openURL("https://www.youtube.com/@Kuriocity_guide");
  };
  const handleSendFeedback = async () => {
    setFeedbackModalVisible(true);
  };
  const handlePasswordReset = async () => {
    sendPasswordResetEmail(auth, user.email);
    setpasswordModalVisible(true);
  };

  return (
    <View style={styles.container}>
      {/* Top Red Bar with Logo */}
      <View style={styles.topBar}>
        <Image
          source={require("../assets/logonaszybkobiale.png")}
          style={styles.logo}
          resizeMode="contain"
        />
      </View>

      {/* Main page content */}
      <ScrollView style={styles.scrollView}>
        <Icon style={styles.userIcon} size={100} name="user-circle-o" />
        <Text style={styles.nameText}>{user.displayName}</Text>
        <Text style={styles.emailText}>{user.email}</Text>
        <Text style={styles.coordinateText}>{userLocation.latitude} {userLocation.longitude}</Text>

        {/* Menu buttons */}
        <TouchableOpacity
          style={styles.buttonContainer}
          onPress={handleSignOut}
        >
          <Icon style={styles.buttonIcon} name="sign-out" />
          <Text style={styles.buttonText}>Sign out</Text>
        </TouchableOpacity>

        <TouchableOpacity
          style={styles.buttonContainer}
          onPress={handlePasswordReset}
        >
          <Icon style={styles.buttonIcon} name="lock" />
          <Text style={styles.buttonText}>Change Password</Text>
        </TouchableOpacity>
        <Modal
          animationType="slide"
          transparent={true}
          visible={passwordModalVisible}
          onRequestClose={() => {
            setpasswordModalVisible(!passwordModalVisible);
          }}
        >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <Pressable
                style={styles.closeButton}
                onPress={() => setpasswordModalVisible(!passwordModalVisible)}
              >
                <Icon name="times" size={20} color="#000" />
              </Pressable>
              <Text style={styles.modalText}>
                An email with the link for password reset has been sent to{" "}
                {user.email}.
              </Text>

              <Pressable
                style={[styles.button, styles.buttonSubmit]}
                onPress={() => setpasswordModalVisible(!passwordModalVisible)}
              >
                <Text style={styles.textStyle}>{"     Ok     "}</Text>
              </Pressable>
            </View>
          </View>
        </Modal>

        {/* <TouchableOpacity style={styles.buttonContainer}>
          <Icon style={styles.buttonIcon} name="envelope" />
          <Text style={styles.buttonText}>Verify Email</Text>
        </TouchableOpacity> */}

        {/* <TouchableOpacity style={styles.buttonContainer}>
          <Icon style={styles.buttonIcon} name="envelope-o" />
          <Text style={styles.buttonText}>Change Email</Text>
        </TouchableOpacity> */}

        <TouchableOpacity
          style={styles.buttonContainer}
          onPress={handleSendFeedback}
        >
          <Icon style={styles.buttonIcon} name="comment" />
          <Text style={styles.buttonText}>Send Feedback</Text>
        </TouchableOpacity>
        <FeedbackModal
          modalVisible={feedbackModalVisible}
          setModalVisible={setFeedbackModalVisible}
        />

        <TouchableOpacity
          style={styles.buttonContainer}
          onPress={handleWebsite}
        >
          <Icon style={styles.buttonIcon} name="globe" />
          <Text style={styles.buttonText}>Our Website</Text>
        </TouchableOpacity>

        <TouchableOpacity
          style={styles.buttonContainer}
          onPress={handleInstagram}
        >
          <Icon style={styles.buttonIcon} name="instagram" />
          <Text style={styles.buttonText}>Instagram</Text>
        </TouchableOpacity>

        <TouchableOpacity style={styles.buttonContainer} onPress={handleTiktok}>
          <Icon style={styles.buttonIcon} name="music" />
          <Text style={styles.buttonText}>TikTok</Text>
        </TouchableOpacity>

        <TouchableOpacity
          style={styles.buttonContainer}
          onPress={handleYoutube}
        >
          <Icon style={styles.buttonIcon} name="youtube-play" />
          <Text style={styles.buttonText}>Youtube</Text>
        </TouchableOpacity>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: "#FFF",
  },
  topBar: {
    backgroundColor: "red",
    paddingTop: 5,
    paddingBottom: 5,
    alignItems: "center",
  },
  logo: {
    width: 150,
    height: 40,
  },
  scrollView: {
    flex: 1,
    padding: 20,
  },
  userIcon: {
    color: "gray",
    marginBottom: 20,
    alignSelf: "center",
  },
  nameText: {
    color: "gray",
    fontSize: 30,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 10,
  },
  emailText: {
    color: "gray",
    fontSize: 20,
    textAlign: "center",
    marginBottom: 10,
  },
  coordinateText: {
    color: "gray",
    fontSize: 10,
    textAlign: "center",
    marginBottom: 20,
  },
  buttonContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: "#f0f0f0",
    borderRadius: 5,
    marginBottom: 10,
    elevation: 2,
  },
  buttonIcon: {
    marginRight: 10,
    fontSize: 20,
    color: "#333",
  },
  buttonText: {
    fontSize: 16,
    color: "#333",
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonSubmit: {
    backgroundColor: "#2196F3",
    marginTop: 20,
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginTop: 10,
    marginBottom: 2,
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
    padding: 10,
  },
  starContainer: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 3,
  },
  commentsInput: {
    height: 100,
    width: "100%",
    borderColor: "gray",
    borderWidth: 1,
    marginTop: 20,
    padding: 10,
  },
});

export default ProfileScreen;

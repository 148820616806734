import React, { useState } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  StyleSheet,
  Pressable,
  Modal,
  TextInput,
} from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import { firestore, storage } from "../../App";
import { addDoc, collection, serverTimestamp } from "firebase/firestore/lite";
import { useAppContext } from "./AppContext";

const FeedbackModal = ({ modalVisible, setModalVisible }) => {
  const [score, setScore] = useState(0);
  const [comments, setComments] = useState("");
  const { user } = useAppContext();

  const handleStarPress = (type, star) => {
    setScore(star);
  };

  const handleSubmit = async () => {
    // Submit the rating to the server or perform any other action
    console.log("Score:", score);
    console.log("Comments:", comments);
    console.log("User:", user.email);

    const locCollection = collection(firestore, "Feedback");

    // Set data in the batch
    addDoc(locCollection, {
      user: user.email,
      score: score,
      comment: comments,
      date: serverTimestamp(),
    });

    // Close the modal
    setModalVisible(false);
  };

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => {
        Alert.alert("Modal has been closed.");
        setModalVisible(!modalVisible);
      }}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <Pressable
            style={styles.closeButton}
            onPress={() => setModalVisible(!modalVisible)}
          >
            <Icon name="times" size={20} color="#000" />
          </Pressable>
          <Text style={styles.modalText}>
            Thanks for sending feedback! Would you recommend Kuriocity to a
            firend?
          </Text>
          <View style={styles.starContainer}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((star) => (
              <TouchableOpacity
                key={star}
                onPress={() => handleStarPress("location", star)}
              >
                <Icon
                  name={score >= star ? "star" : "star-o"}
                  size={30}
                  color={score >= star ? "#FFD700" : "#D3D3D3"}
                />
              </TouchableOpacity>
            ))}
          </View>
          <Text style={styles.modalText}>
            Do you have any other comments / suggestions / feedback?
          </Text>
          <TextInput
            style={styles.commentsInput}
            placeholder="(optional)"
            onChangeText={(text) => setComments(text)}
            value={comments}
          />
          <Pressable
            style={[styles.button, styles.buttonSubmit]}
            onPress={handleSubmit}
          >
            <Text style={styles.textStyle}>Submit</Text>
          </Pressable>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonSubmit: {
    backgroundColor: "#2196F3",
    marginTop: 20,
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginTop: 10,
    marginBottom: 2,
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
    padding: 10,
  },
  starContainer: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 3,
  },
  commentsInput: {
    height: 100,
    width: "100%",
    borderColor: "gray",
    borderWidth: 1,
    marginTop: 20,
    padding: 10,
  },
});

export default FeedbackModal;

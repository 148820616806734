import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Button,
  Image,
  ScrollView,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Modal,
  Pressable,
} from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import Geolocation from "react-native-geolocation-service";
import { useFocusEffect } from "@react-navigation/native";

import LocationItem from "../components/LocationItem";
import { useAppContext } from "../components/AppContext";
import NewUserModal from "../components/NewUserModal";
import GYGCityWidget from "../components/GYGCityWidget.web";
import NoServiceModal from "../components/NoServiceModal";
import AudioControlsBar from "../components/AudioControlsBar";
import NavigationBar from "../components/NavigationBar";
import LogInModal from "../components/LogInModal";

//const firestore = getFirestore();

//console.log("Hello Firestore");

function LogInScreen2({ route, navigation }) {
  const {
    userLocation,
    setUserLocation,
    locations,
    fetchNearbyLocations,
    newUser,
    setNewUser,
    appOff,
  } = useAppContext();

  //Refresh locations button
  const [isLogInModalVisible, setLogInModalVisible] = useState(true);
  const getNearbyLocations = async () => {};

  //Getting the locations in the right order based on distance
  const sortedLocations = locations
    .slice()
    .sort((a, b) => a.distance - b.distance);

  useFocusEffect(() => {
    setLogInModalVisible(true); // Set isLogInModalVisible to true when the screen comes into focus
  });

  return (
    <View style={styles.container}>
      {/* Top Red Bar with Logo */}
      <View style={styles.topBar}>
        <Image
          source={require("../assets/logonaszybkobiale.png")}
          style={styles.logo}
          resizeMode="contain"
        />
        <TouchableOpacity onPress={getNearbyLocations}>
          <Icon name="repeat" size={30} color="white" style={styles.icon} />
        </TouchableOpacity>
      </View>
      <NewUserModal modalVisible={newUser} setModalVisible={setNewUser} />

      {/* Scroll View for App Content */}
      <ScrollView style={styles.scrollView}>
        {/* Your App Content Goes Here */}

        {sortedLocations.map((location, index) => {
          const shouldRenderWidget = index < 45 && (index + 1) % 15 === 5;

          return (
            <React.Fragment key={index}>
              {shouldRenderWidget && <GYGCityWidget />}
              <LocationItem location={location} />
            </React.Fragment>
          );
        })}
      </ScrollView>
      <AudioControlsBar />

      <NavigationBar styles={styles.navigationContainer} />
      <LogInModal
        modalVisible={isLogInModalVisible}
        setModalVisible={setLogInModalVisible}
      ></LogInModal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    color: "white",
    justifyContent: "center",
  },
  topBar: {
    backgroundColor: "red",
    paddingTop: 5,
    paddingBottom: 5,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  logo: {
    width: 150,
    height: 40,
    alignSelf: "flex-start",
  },
  gygContainer: {
    height: 250,
  },
  scrollView: {
    flex: 1,
    backgroundColor: "white", //"#F0F0F0",
  },
  coordinatesText: {
    alignSelf: "center",
  },
  audioBar: {
    flexDirection: "row",
    backgroundColor: "red",
    height: 50,
    alignItems: "center",
  },
  playIcon: {
    color: "white",
    marginRight: 5,
    alignSelf: "flex-end",
  },
  bottomBar: {
    flexDirection: "row",
    backgroundColor: "#f2f6fc",
    height: 50,
  },

  button: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  selectedIcon: {
    color: "red",
  },
  unselectedIcon: {
    color: "black",
  },
  icon: {
    marginRight: 10,
  },
});
export default LogInScreen2;

import React from "react";
import { Helmet } from "react-helmet";

const GYGCityWidget = () => {
  return (
    <>
      <Helmet>
        {/* Add the script to the <head> */}

        <script
          async
          defer
          src="https://widget.getyourguide.com/dist/pa.umd.production.min.js"
          data-gyg-partner-id="A7OWFWA"
        ></script>
      </Helmet>
      {/* Render the widget HTML */}
      <div
        data-gyg-widget="auto"
        data-gyg-partner-id="A7OWFWA"
        data-gyg-cmp="Main Screen"
      ></div>
    </>
  );
};

export default GYGCityWidget;

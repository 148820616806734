import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  Modal,
  TextInput,
  StyleSheet,
} from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import {
  GoogleAuthProvider,
  signInWithRedirect,
  getRedirectResult,
  signInWithPopup,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../components/AppContext";
import { loginEmailPassword, auth } from "../../App";
import { useNavigation } from "@react-navigation/native";
import GoogleSignInButton from "./GoogleSignInButton";

const LogInModal = ({ modalVisible, setModalVisible }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { setUser } = useAppContext();
  const navigation = useNavigation();

  const provider = new GoogleAuthProvider();

  const handleLogin = async () => {
    try {
      const user = await loginEmailPassword(email, password);
      setUser(user);
      // User successfully logged in, navigate to MainScreen
      // navigation.replace('MainScreen');
    } catch (error) {
      // Handle login failure
      setErrorMessage("Incorrect email or password");
    }
  };

  const handleGoogleLogin = async () => {
    try {
      // await signInWithRedirect(auth, provider);
      signInWithPopup(auth, provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          //const credential = GoogleAuthProvider.credentialFromResult(result);
          //const token = credential.accessToken;
          // The signed-in user info.
          const user = result.user;
          console.log(user);
          setUser(user);
          // IdP data available using getAdditionalUserInfo(result)
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage);
          // The email of the user's account used.
          // const email = error.customData.email;
          // // The AuthCredential type that was used.
          // const credential = GoogleAuthProvider.credentialFromError(error);
          // // ...
        });
    } catch (error) {
      // Handle login failure
      setErrorMessage("Error during Google login");
    }
  };

  const handleSignUp = () => {
    navigation.navigate("NewAccount");
    setModalVisible(false); // Close the modal when navigating to make account screen
  };

  const handleForgotPassword = () => {
    navigation.navigate("Forgor");
    setModalVisible(false); // Close the modal when navigating to forgot password screen
  };

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => {
        setModalVisible(!modalVisible);
      }}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <View style={styles.logoBackground}>
            <Image
              source={require("../assets/logonaszybkobiale.png")}
              style={styles.logo}
              resizeMode="contain"
            />
          </View>
          <Text style={styles.topText}>Just one more step!</Text>
          <Text>Log in and you will be ready to explore.</Text>
          <TextInput
            placeholder="Email"
            value={email}
            onChangeText={setEmail}
            style={styles.input}
          />
          <TextInput
            placeholder="Password"
            value={password}
            onChangeText={setPassword}
            secureTextEntry
            style={styles.input}
          />
          {errorMessage ? (
            <Text style={styles.errorMessage}>{errorMessage}</Text>
          ) : null}
          <TouchableOpacity onPress={handleForgotPassword}>
            <Text style={styles.forgotPasswordLink}>Forgot password?</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={handleLogin} style={styles.button}>
            <Text style={styles.buttonText}>Log In</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={handleGoogleLogin}>
            <GoogleSignInButton />
          </TouchableOpacity>
          <Text style={styles.orText}>-------- OR --------</Text>

          <TouchableOpacity onPress={handleSignUp} style={styles.button}>
            <Text style={styles.buttonText}>Make a free email account</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalView: {
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    width: "90%",
    maxWidth: 400,
  },
  logoBackground: {
    backgroundColor: "red",
    borderRadius: 10,
    marginBottom: 15,
  },
  logo: {
    width: 200,
    height: 75,
  },
  input: {
    backgroundColor: "#f0f0f0",
    width: "100%",
    padding: 15,
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 5,
  },
  button: {
    backgroundColor: "red",
    width: "100%",
    height: "40px",
    padding: 15,
    justifyContent: "center",
    marginBottom: 10,
    marginTop: 10,
    borderRadius: 5,
  },
  buttonText: {
    color: "white",
    textAlign: "center",
  },
  forgotPasswordLink: {
    color: "#007bff",
    marginBottom: 20,
  },
  errorMessage: {
    color: "red",
    marginBottom: 20,
    textAlign: "center",
  },
  orText: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 10,
    marginTop: 10,
  },
  topText: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 10,
  },
});

export default LogInModal;

import React, { useState } from "react";
import {
  View,
  Text,
  Pressable,
  Modal,
  TextInput,
  StyleSheet,
  Linking,
} from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import { useAppContext } from "../components/AppContext";
import { TouchableOpacity } from "react-native-web";
import { doc, serverTimestamp, setDoc } from "firebase/firestore/lite";
import { firestore } from "../../App";

const NewUserModal = ({ modalVisible, setModalVisible }) => {
  const { user, newGoogle } = useAppContext();

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [receiveUpdates, setReceiveUpdates] = useState(false);

  const handleSubmit = async () => {
    setModalVisible(false);
  };

  const handleGoogleSubmit = async () => {
    const userInfoDoc = doc(firestore, "UserInfo", user.email);
    setDoc(userInfoDoc, {
      email: user.email,
      name: user.displayName,
      newsletter: receiveUpdates,
      date: serverTimestamp(),
    });
    setModalVisible(false);
  };

  const handleTermsPress = () => {
    Linking.openURL("https://kuriocity.com/privacy-policy/");
  };

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => {
        setModalVisible(!modalVisible);
      }}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <Text style={styles.modalText}>Welcome to Kuriocity!</Text>
          <Text style={styles.modalTextSmall}>
            Thank you for choosing to explore with us! {"\n\n"}
            Verify your email by following the link we sent to keep access to
            the account. {"\n\n"}
            Find nearby locations here or browse the map to discover what is out
            there! {"\n\n"}
            Enjoy! {"\n"}
            Team Kuriocity
          </Text>

          {newGoogle ? (
            <View style={styles.checkboxContainer}>
              <TouchableOpacity
                onPress={() => setTermsAccepted(!termsAccepted)}
                style={styles.checkbox}
              >
                {termsAccepted ? (
                  <Text style={styles.checkboxText}>☑️</Text>
                ) : (
                  <Text style={styles.checkboxText}>▢</Text>
                )}
                <Text style={styles.checkboxText}>
                  Accept{" "}
                  <TouchableOpacity onPress={handleTermsPress}>
                    <Text style={styles.signUpLink}>Terms and Conditions*</Text>
                  </TouchableOpacity>
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => setReceiveUpdates(!receiveUpdates)}
                style={styles.checkbox}
              >
                {receiveUpdates ? (
                  <Text style={styles.checkboxText}>☑️</Text>
                ) : (
                  <Text style={styles.checkboxText}>▢</Text>
                )}
                <Text style={styles.checkboxText}>
                  Receive updates about KurioCity and special offers through
                  email
                </Text>
              </TouchableOpacity>
            </View>
          ) : null}

          {newGoogle ? (
            <Pressable
              style={[
                styles.button,
                termsAccepted
                  ? styles.buttonSubmit
                  : styles.buttonSubmitInactive,
              ]}
              onPress={termsAccepted ? handleGoogleSubmit : null}
            >
              <Text style={styles.textStyle}>Let's explore</Text>
            </Pressable>
          ) : (
            <Pressable
              style={[styles.button, styles.buttonSubmit]}
              onPress={handleSubmit}
            >
              <Text style={styles.textStyle}>Let's explore</Text>
            </Pressable>
          )}
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonSubmit: {
    backgroundColor: "#2196F3",
    marginTop: 20,
  },
  buttonSubmitInactive: {
    backgroundColor: "#c9c9c9",
    marginTop: 20,
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginTop: 10,
    marginBottom: 20,
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 18,
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
    padding: 10,
  },
  modalTextSmall: {
    marginTop: 10,
    marginBottom: 20,
    textAlign: "center",
    fontSize: 15,
  },
  checkboxContainer: {
    marginTop: 10,
    marginBottom: 10,
    alignItems: "center",
  },
  checkbox: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 5,
  },
  checkboxText: {
    color: "black",
    marginLeft: 5,
  },
});

export default NewUserModal;

import React, { useState } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  StyleSheet,
  Pressable,
  Modal,
  TextInput,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import Icon from "react-native-vector-icons/FontAwesome";
import { useAppContext } from "../components/AppContext";
import { firestore, storage } from "../../App";
import { addDoc, collection, serverTimestamp } from "firebase/firestore/lite";

const ReviewModal = ({ modalVisible, setModalVisible, location }) => {
  const navigation = useNavigation();

  const [locationRating, setLocationRating] = useState(0);
  const [guideRating, setGuideRating] = useState(0);
  const [comments, setComments] = useState("");
  const { reviewedLocations, setReviewedLocations, user } = useAppContext();

  const handleStarPress = (type, star) => {
    if (type === "location") {
      setLocationRating(star);
    } else if (type === "guide") {
      setGuideRating(star);
    }
  };

  const handleSubmit = async () => {
    // Submit the rating to the server or perform any other action
    console.log("Location rating:", locationRating);
    console.log("Guide rating:", guideRating);
    console.log("Comments:", comments);
    console.log("User:", user.email);

    const locCollection = collection(firestore, "UserReviews");

    // Set data in the batch
    addDoc(locCollection, {
      user: user.email,
      locationReview: locationRating,
      guideReview: guideRating,
      locationName: location.title,
      locationID: location.id,
      comments: comments,
      date: serverTimestamp(),
    });

    setReviewedLocations([...reviewedLocations, location.id]);
    // Close the modal
    setModalVisible(false);
  };

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => {
        Alert.alert("Modal has been closed.");
        setModalVisible(!modalVisible);
      }}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <Pressable
            style={styles.closeButton}
            onPress={() => setModalVisible(!modalVisible)}
          >
            <Icon name="times" size={20} color="#000" />
          </Pressable>
          <Text style={styles.modalText}>
            How would you rate the location itself?
          </Text>
          <View style={styles.starContainer}>
            {[1, 2, 3, 4, 5].map((star) => (
              <TouchableOpacity
                key={star}
                onPress={() => handleStarPress("location", star)}
              >
                <Icon
                  name={locationRating >= star ? "star" : "star-o"}
                  size={30}
                  color={locationRating >= star ? "#FFD700" : "#D3D3D3"}
                />
              </TouchableOpacity>
            ))}
          </View>
          <Text style={styles.modalText}>How would you rate the guide?</Text>
          <View style={styles.starContainer}>
            {[1, 2, 3, 4, 5].map((star) => (
              <TouchableOpacity
                key={star}
                onPress={() => handleStarPress("guide", star)}
              >
                <Icon
                  name={guideRating >= star ? "star" : "star-o"}
                  size={30}
                  color={guideRating >= star ? "#FFD700" : "#D3D3D3"}
                />
              </TouchableOpacity>
            ))}
          </View>
          <TextInput
            style={styles.commentsInput}
            placeholder="Other comments (optional)"
            onChangeText={(text) => setComments(text)}
            value={comments}
          />
          <Pressable
            style={[styles.button, styles.buttonSubmit]}
            onPress={handleSubmit}
          >
            <Text style={styles.textStyle}>Submit</Text>
          </Pressable>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonSubmit: {
    backgroundColor: "#2196F3",
    marginTop: 20,
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginTop: 10,
    marginBottom: 2,
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
    padding: 10,
  },
  starContainer: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 3,
  },
  commentsInput: {
    height: 100,
    width: "100%",
    borderColor: "gray",
    borderWidth: 1,
    marginTop: 20,
    padding: 10,
  },
});

export default ReviewModal;

import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useAppContext } from "./AppContext";

const ReCaptcha = ({ onVerify }) => {
  const { reCaptchaVerified, setreCaptchaVerified } = useAppContext();
  const handleVerify = (token) => {
    // Handle the reCAPTCHA token verification
    setreCaptchaVerified(true);
    onVerify(token);
  };
  const handleExpire = (token) => {
    // Handle the reCAPTCHA token verification
    setreCaptchaVerified(false);
  };

  return (
    <ReCAPTCHA
      sitekey="6LciqaApAAAAAIaHgokM5QfeWrbsIprBh_HgZ-DR"
      onChange={handleVerify}
      onExpired={handleExpire}
    />
  );
};

export default ReCaptcha;

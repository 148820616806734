import React, { useState } from "react";
import {
  View,
  Image,
  TextInput,
  TouchableOpacity,
  Text,
  Linking,
  StyleSheet,
} from "react-native";

import { firestore, storage, auth } from "../../App";
import { useNavigation } from "@react-navigation/native";
import { sendPasswordResetEmail } from "firebase/auth";

const ForgotPasswordScreen = () => {
  const [email, setEmail] = useState("");
  const [sending, setSending] = useState(false); // State to track sending status
  const navigation = useNavigation();

  const handleSend = () => {
    setSending(true); // Set sending to true when button is pressed
    // Open www.topwanders.com in the default browser
    sendPasswordResetEmail(auth, email);
    // Navigate back after a delay
    setTimeout(() => {
      navigation.goBack();
    }, 1000); // Delay of 1 second
  };

  const handleBack = () => {
    navigation.goBack();
  };

  return (
    <View style={styles.container}>
      <Image
        source={require("../assets/logonaszybkobiale.png")}
        style={styles.logo}
        resizeMode="contain"
      />

      <Text style={styles.forgotPasswordText}>Forgot your password?</Text>
      <Text style={styles.instructionsText}>
        Enter the email you used to join. If we have this email in our database,
        we will send over instructions to reset your password.
      </Text>
      <TextInput
        placeholder="Email"
        value={email}
        onChangeText={setEmail}
        style={styles.input}
      />
      <TouchableOpacity
        onPress={handleSend}
        style={styles.loginButton}
        disabled={sending} // Disable button while sending
      >
        <Text>{sending ? "Sending..." : "Send a reset link"}</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={handleBack}>
        <Text style={styles.backButton}>Back</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "red",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    width: 350,
    height: 100,
  },
  input: {
    backgroundColor: "white",
    width: 200,
    padding: 10,
    marginVertical: 10,
    borderRadius: 5,
  },
  loginButton: {
    backgroundColor: "white",
    padding: 10,
    marginVertical: 10,
    borderRadius: 5,
  },
  backButton: {
    color: "white",
    textDecorationLine: "underline",
  },
  errorMessage: {
    color: "white",
    marginTop: 10,
  },
  forgotPasswordText: {
    color: "white",
    fontSize: 25, // Adjust font size as per your design
    marginBottom: 10, // Add margin bottom for spacing
    marginTop: 15,
    textAlign: "center", // Center align the text
  },
  instructionsText: {
    color: "white",
    fontSize: 14, // Adjust font size as per your design
    textAlign: "center", // Center align the text
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 25,
  },
});

export default ForgotPasswordScreen;

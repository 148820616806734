import React, { useState } from "react";
import {
  View,
  Text,
  Pressable,
  Modal,
  TextInput,
  StyleSheet,
} from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import { useAppContext } from "../components/AppContext";
import { firestore } from "../../App";
import { addDoc, collection, serverTimestamp } from "firebase/firestore/lite";

const ReportModal = ({ modalVisible, setModalVisible, location }) => {
  const { user } = useAppContext();
  const [comments, setComments] = useState("");
  const [issues, setIssues] = useState({
    wrongCoordinates: false,
    audioIssue: false,
    textIssue: false,
    photoIssue: false,
    sourceIssue: false,
    falseInformation: false,
    otherIssue: false,
  });

  const handleSubmit = async () => {
    const reportData = {
      user: user.email,
      locationName: location.title,
      locationID: location.id,
      comments: comments,
      date: serverTimestamp(),
      issues: issues,
    };

    // Submit the report data to the server or perform any other action
    console.log("Report Data:", reportData);

    // Uncomment below lines to submit data to Firestore
    const reportCollection = collection(firestore, "Reports");
    await addDoc(reportCollection, reportData);

    // Close the modal
    setModalVisible(false);
  };

  const toggleIssue = (issue) => {
    setIssues({ ...issues, [issue]: !issues[issue] });
  };

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => {
        setModalVisible(!modalVisible);
      }}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <Pressable
            style={styles.closeButton}
            onPress={() => setModalVisible(!modalVisible)}
          >
            <Icon name="times" size={20} color="#000" />
          </Pressable>
          <Text style={styles.modalText}>Report Location</Text>
          <Text>
            Thank you for reporting the location. Please select the issues:
          </Text>
          <View style={styles.checkboxContainer}>
            <View style={styles.checkboxItem}>
              <Pressable
                style={[
                  styles.checkbox,
                  issues.wrongCoordinates && styles.checked,
                ]}
                onPress={() => toggleIssue("wrongCoordinates")}
              >
                {issues.wrongCoordinates && (
                  <Icon name="check" size={20} color="#FFF" />
                )}
              </Pressable>
              <Text>The coordinates don't match the location</Text>
            </View>
            <View style={styles.checkboxItem}>
              <Pressable
                style={[styles.checkbox, issues.audioIssue && styles.checked]}
                onPress={() => toggleIssue("audioIssue")}
              >
                {issues.audioIssue && (
                  <Icon name="check" size={20} color="#FFF" />
                )}
              </Pressable>
              <Text>Issues with the audio</Text>
            </View>
            <View style={styles.checkboxItem}>
              <Pressable
                style={[styles.checkbox, issues.textIssue && styles.checked]}
                onPress={() => toggleIssue("textIssue")}
              >
                {issues.textIssue && (
                  <Icon name="check" size={20} color="#FFF" />
                )}
              </Pressable>
              <Text>Issues with the guide text</Text>
            </View>
            <View style={styles.checkboxItem}>
              <Pressable
                style={[styles.checkbox, issues.photoIssue && styles.checked]}
                onPress={() => toggleIssue("photoIssue")}
              >
                {issues.photoIssue && (
                  <Icon name="check" size={20} color="#FFF" />
                )}
              </Pressable>
              <Text>Issues with the photo</Text>
            </View>
            <View style={styles.checkboxItem}>
              <Pressable
                style={[styles.checkbox, issues.sourceIssue && styles.checked]}
                onPress={() => toggleIssue("sourceIssue")}
              >
                {issues.sourceIssue && (
                  <Icon name="check" size={20} color="#FFF" />
                )}
              </Pressable>
              <Text>Issues with the sources/attributions</Text>
            </View>
            <View style={styles.checkboxItem}>
              <Pressable
                style={[
                  styles.checkbox,
                  issues.falseInformation && styles.checked,
                ]}
                onPress={() => toggleIssue("falseInformation")}
              >
                {issues.falseInformation && (
                  <Icon name="check" size={20} color="#FFF" />
                )}
              </Pressable>
              <Text>False Information</Text>
            </View>
            <View style={styles.checkboxItem}>
              <Pressable
                style={[styles.checkbox, issues.otherIssue && styles.checked]}
                onPress={() => toggleIssue("otherIssue")}
              >
                {issues.otherIssue && (
                  <Icon name="check" size={20} color="#FFF" />
                )}
              </Pressable>
              <Text>Other issues</Text>
            </View>
          </View>
          <TextInput
            style={styles.commentsInput}
            placeholder="Other comments (optional)"
            onChangeText={(text) => setComments(text)}
            value={comments}
          />
          <Pressable
            style={[styles.button, styles.buttonSubmit]}
            onPress={handleSubmit}
          >
            <Text style={styles.textStyle}>Submit</Text>
          </Pressable>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonSubmit: {
    backgroundColor: "#2196F3",
    marginTop: 20,
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginTop: 10,
    marginBottom: 20,
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 18,
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
    padding: 10,
  },
  checkboxContainer: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  checkboxItem: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 5,
  },
  checkbox: {
    width: 20,
    height: 20,
    borderWidth: 1,
    borderColor: "#999",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 10,
  },
  checked: {
    backgroundColor: "#2196F3",
  },
  commentsInput: {
    height: 100,
    width: "100%",
    borderColor: "gray",
    borderWidth: 1,
    marginTop: 20,
    padding: 10,
  },
});

export default ReportModal;
